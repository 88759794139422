<template>
   <main class="bg-white flex md:flex-row flex-col  gap-8 p-[24px] ">
      <section class="w-full md:w-3/12  order-first md:order-last ">
         <!-- See all last ticket -->

         <div class=" sticky top-[15%]" id="accordion-collapse" data-accordion="collapse">
            <div class="text-2xl" id="accordion-collapse-heading-1">
               <button
                  type="button"
                  class="flex items-center justify-between w-full p-[8px] font-medium text-left border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
                  data-accordion-target="#accordion-collapse-body-1"
                  aria-expanded="true"
                  aria-controls="accordion-collapse-body-1"
               >
                  <span class="text-[0.8em] font-bold"
                     >Vos dernières demandes</span
                  >
            
               </button>
            </div>
            <div
               id="accordion-collapse-body-1"
               class="overflow-auto h-[70vh] border-[1px]"
               aria-labelledby="accordion-collapse-heading-1"
            >
               <div
               
                  v-for="(item, index) in xDisplayAllTicket"
                  :key="index"
                  
                  class="p-[14px] flex flex-col gap-2 font-light border-t-[1px] border-gray-200 dark:border-gray-700 dark:bg-gray-900 hover:bg-gray-100 cursor-pointer"
               >
                  <span class="text-md" @click="qGoToSeeMessage(item.id)"
                     >#{{ item.code }} -
                     <span class="text-sm font-bold">{{
                        qSubstring(item.libelle)
                     }}</span>
                  </span>
                  <div class="flex justify-between text-xs ">
                     <span
                        class="font-bold"
                        :class="
                           item.status === 0
                              ? 'text-yellow-500'
                              : 'text-green-500'
                        "
                     >
                        {{ item.status === 0 ? 'En attente' : 'Repondu' }}
                     </span>
                     <span class="font-[500] ">
                        {{ qTime(item.created_at) }}
                     </span>
                  </div>
               </div>

               <div
                  v-if="xDisplayAllTicket.length === 0"
                  class=" w-full h-full flex flex-col justify-center items-center py-8 px-4 gap-2"
               >
                  <img style="width: 10em" src="/empty.svg" alt="" />

                  <span class="text-[12px] text-center">
                     Aucune donnée, n'a été trouver
                  </span>
               </div>
            </div>
         </div>

         <!-- See other -->
         <div class=""></div>
      </section>

      <section class="w-full md:w-9/12 border-[1px] ">
         <div class=" m-2 md:m-5">
            <div class="flex text-2xl border-b-[1px] py-[4px] ">
               <span> Créer une nouvelle demande d'assistance </span>
            </div>

            <div class="flex flex-col gap-4 py-8">
               <!--  -->
               <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                  <div class="flex flex-col gap-1 font-bold">
                     <span class="text-sm">Nom de l'entrepise</span>
                     <input
                        v-model="xDataTicket.name" disabled
                        class="bg-white p-[8px] focus:bg-white  rounded-sm placeholder:font-light font-bold text-gray-900/50 outline-none border-[1px] "
                        placeholder="Entrez votre nom complet"
                        type="text"
                     />
                  </div>

                  <div class="flex flex-col gap-1 font-bold">
                     <span class="text-sm">Adresse email</span>
                     <input
                        v-model="xDataTicket.email" disabled
                        class="bg-white p-[8px] focus:bg-white  rounded-sm placeholder:font-light font-bold text-gray-900/50 outline-none border-[1px]"
                        placeholder="contact@ediqia.com"
                        type="text"
                     />
                  </div>
               </div>

               <!--  -->
               <div class="flex flex-col gap-1 font-bold">
                  <span class="flex flex-col gap-1 font-bold text-sm"
                     >Objet</span
                  >
                  <input
                     v-model="xDataTicket.objet"
                     class="bg-white p-[8px] focus:bg-white  rounded-sm placeholder:font-light font-bold text-gray-900/50 outline-none border-[1px]"
                     placeholder="Quel est le problème que vous rencontrez"
                     type="text"
                  />
                  <span
                     v-if="xErrorDataTicket.path === 'objet'"
                     class="text-red-500 text-[10px] "
                  >
                     {{ xErrorDataTicket.message }}
                  </span>
               </div>

               <!--  -->
               <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                  <div class="flex flex-col gap-1 ">
                     <span class="flex flex-col gap-1 font-bold text-sm"
                        >Service lié</span
                     >
                     <select
                        class="bg-white p-[8px] focus:bg-white  rounded-sm placeholder:font-light font-bold text-gray-900/50 outline-none border-[1px]"
                        id=""
                        v-model="xDataTicket.service"
                     >
                        <option
                           class="py-2"
                           :value="item"
                           v-for="(item, index) in xListService"
                           :key="index"
                        >
                           {{ item }}
                        </option>
                     </select>
                  </div>

                  <div class="flex flex-col gap-1">
                     <span class="flex flex-col gap-1 font-bold text-sm"
                        >Priorité</span
                     >
                     <select
                        class="bg-white p-[8px] focus:bg-white  rounded-sm placeholder:font-light font-bold text-gray-900/50 outline-none border-[1px]"
                        name=""
                        id=""
                        v-model="xDataTicket.priority"
                     >
                        <option
                           :value="item"
                           v-for="(item, index) in xListPriority"
                           :key="index"
                        >
                           {{ item }}
                        </option>
                     </select>
                  </div>
               </div>

               <!--  -->
               <div class="flex flex-col gap-1 ">
                  <span class="flex flex-col gap-1 font-bold text-sm"
                     >Messagae</span
                  >
                  <textarea
                     v-model="xDataTicket.message"
                     rows="10"
                     cols="40"
                     class="bg-white p-[8px] focus:bg-white  rounded-sm placeholder:font-light font-bold text-gray-900/50 outline-none border-[1px]"
                     placeholder="Quelle est donc votre préoccupation..."
                     name=""
                     id=""
                  >
                  </textarea>
                  <span
                     v-if="xErrorDataTicket.path === 'description'"
                     class="text-red-500 text-[10px] "
                  >
                     {{ xErrorDataTicket.message }}
                  </span>
               </div>

               <!--  -->
               <div class="flex  w-full py-8 gap-2">
                  <div class="flex flex-col w-full gap-4">
                     <div
                        class="border-[0.5px] p-[8px] rounded-md "
                        v-for="(image, index) in xImageToTickets"
                        :key="index"
                     >
                        <input
                           @input="fileUplaod"
                           class=""
                           type="file"
                           accept="image/png, image/jpeg"
                           :name="image.name"
                           :id="image.name"
                        />
                     </div>
                  </div>
                  <div class="py-1">
                     <div
                        @click="qAddMoreImages"
                        class="flex justify-center cursor-pointer bg-purple-900 px-[16px] py-[4px] text-white text-centre font-bold outline-none rounded-md "
                        style="ra"
                     >
                        Ajouter
                     </div>
                  </div>
               </div>

               <!--  -->
               <div class="flex justify-center gap-2">
                  <button
                     type="submit"
                     @click="qSendTicket"
                     class=" px-[24px] rounded py-[8px] bg-purple-900 text-white font-bold "
                     :disabled="loading === true ? true : false"
                  >
                     <span v-if="loading === false">Ajouter</span>
                     <b-spinner
                        v-if="loading === true"
                        label="Spinning"
                     ></b-spinner>
                  </button>

                  <a href="/"
                     class=" px-[24px] rounded py-[8px] border-[1px] border-purple-900 text-purple-900 font-bold "
                  >
                     Annuler
                  </a>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import URL from '../request';
import axios from 'axios';
import { toast_sucess } from '@/utils/qToast';
import { qDecryptedJson } from '@/services/qeCrypt';
export default {
   setup(props, {root}) {
      // Defined Data
      const xGetTicket = ref([]);
      const xErrorDataTicket = reactive({
         path: '',
         message: '',
      });
      const xListService = reactive([
         'Facturations',
         'Dépenses',
         'Trésorerie',
         'CRM',
         'Catalogues',
         'Entréprise',
      ]);
      const xListPriority = reactive(['Élever', 'Haut', 'Moyenne', 'Faible']);
      const xImageToTickets = reactive([
         {
            name: 'xImage_0',
            file: '',
         },
      ]);

      const xDataTicket = reactive({
         name: '',
         email: '',
         objet: '',
         service: xListService[0],
         priority: xListPriority[0],
         message: '',
      });
      const xDisplayAllTicket = ref([]);
      const loading = ref(false);

      onMounted(() => {
         

         qGetUserConnect()
         qGetAllTickets();
          
         
         
      });


      const qGetUserConnect = async( ) => {

         try {
         const { data } = await axios.get(URL.CONNECTED_USER)

         // 
         const HOLA = data[0].user_connecte[0]
         xDataTicket.name = HOLA.nom
         xDataTicket.email = HOLA.email

         } catch (error) {
            console.log(error)
         }

      }

      const qSubstring = (text) => {
         return text.substring(0, 20);
      };

      // Methods

      // Validate
      const qValidateDataTicket = async (request) => {
         let error = false;

         if (request.objet === '') {
            xErrorDataTicket.path = 'objet';
            xErrorDataTicket.message = "L'objet est requis";
            error = true;
         } else if (request.message === '') {
            xErrorDataTicket.path = 'description';
            xErrorDataTicket.message = 'La description est requis';
            error = true;
         }

         return {
            error,
         };
      };

      /**
     @Comments Get all tickets
     @Methods GET
     */
      const qGetAllTickets = async () => {
         try {
            const { data } = await axios.get(URL.TICKET_LIST);
            if (data) {
               // console.log(data.ticket);
               xDisplayAllTicket.value = data.ticket.reverse();
            }
         } catch (error) {
            console.log(error);
         }
      };

      const fileUplaod = async (e) => {
         const index = e.target.name;

         xImageToTickets.forEach((el) => {
            if (index === el.name) {
               el.file = e.target.files[0];
            }
         });

         // console.log(xImageToTickets);
      };

      /**
     @Comments Send a ticket
     @Methods POST
     */
      const qSendTicket = async () => {
         loading.value = true;
         try {
            const { error } = await qValidateDataTicket(xDataTicket);
            if (!error) {
               xErrorDataTicket.path = '';
               xErrorDataTicket.message = '';

               const dataRequest = {
                  email: xDataTicket.email,
                  libelle: xDataTicket.objet,
                  description: xDataTicket.message,
                  service: xDataTicket.service,
                  priorite: xDataTicket.priority,
               };

               const requestTicket = new FormData();
               requestTicket.append('email', xDataTicket.email);
               requestTicket.append('libelle', xDataTicket.objet);
               requestTicket.append('description', xDataTicket.message);
               requestTicket.append('service', xDataTicket.service);
               requestTicket.append('priorite', xDataTicket.priority);
               for (let i = 0; i < xImageToTickets.length; i++) {
                  const image = xImageToTickets[i];
                  requestTicket.append(`images[${i}]`, image);
               }

               const { data } = await axios.post(
                  URL.TICKET_STORE,
                  requestTicket
               );
               if (data) {
                  loading.value = false;
                  xDisplayAllTicket.value.push({
                     libelle: xDataTicket.objet,
                     code: 'TK00000',
                     created_at: new Date(),
                     status: 0
                  })
                  toast_sucess(root, 'success', 'top-right', 'Votre ticket à été envoyer avec success !')
                  xDataTicket.email = '';
                  xDataTicket.objet = '';
                  xDataTicket.message = '';
               }
            }
         } catch (error) {
            loading.value = false;
            console.log(error);
         }
      };

      /**
       * @
       */
      const qTime = (_at) => {
         
         let diff = { sec: 0, min: 0, hour: 0, day: 0 }; // Initialisation du retour
         let tmp = new Date() - new Date(_at);

         tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
         diff.sec = tmp % 60; // Extraction du nombre de secondes

         tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
         diff.min = tmp % 60; // Extraction du nombre de minutes

         tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
         diff.hour = tmp % 24; // Extraction du nombre d'heures

         tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
         diff.day = tmp;

         if (diff.day === 0 && diff.hour != 0) {
            return `il ya ${diff.hour} h ${diff.min} min`;
         } else if (diff.day != 0) {
            return `il ya ${diff.day} Jours`;
         } else if (diff.hour === 0) {
            return `il ya ${diff.min} min`;
         }
      };

      /**
       * @
       */
      const qAddMoreImages = () => {
         const index = xImageToTickets.length;
         // console.log(index);
         xImageToTickets.push({ name: `xImage_${index}` });
      };
      
      /**
       * @
       */
      const qGoToSeeMessage = (_id) => {
         console.log('test')
        root.$store.commit('qParametre/TICKET_ID', _id, {
            root: true,
         });
         root.$router.push({name: 'Chat'})
      };


      return {
         xImageToTickets,
         qAddMoreImages,
         qSendTicket,
         xDisplayAllTicket,
         xImageToTickets,
         xDataTicket,
         xListService,
         xListPriority,
         fileUplaod,
         qTime,
         qSubstring,
         xErrorDataTicket,
         loading,
         qGoToSeeMessage
      };
   },
};
</script>

<style></style>
